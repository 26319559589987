:root {
    --ck-color-toolbar-border: #e4e6ef;
    --ck-color-base-border: #e4e6ef;
}

/*.brand-logo svg {
    min-height: 45px;
    width: auto;
}*/
.brand-logo svg {
    min-height: 45px;
    width: 100%;
    height: 45px;
}
svg.logo-default {
    width: 35px;
    height: 35px;
}

.daterangepicker td.today {
    background-color: $dark;
    color: $white;
    &:hover {
        background-color: $dark;
        color: $white;
    }
}
.select2-has-error > div {
    border-color: #f64e60;
    padding-right: calc(1.5em + 1.3rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F64E60' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F64E60' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.325rem) center;
    background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

.modal-backdrop.show {
    opacity: 0.5 !important;
}

.rounded-md {
    border-radius: 0.42rem !important;
}

.badge.badge-count-mini {
    border-radius: 16px;
    font-size: 0.8em;
    text-align: center;
    min-width: 16px;
    height: 16px;
    padding: 2px;
    padding-top: 4px;
}

.h-full {
    height: 100%;
}

.ck-editor__editable_inline {
    min-height: calc(50vh - 150px);
}

.sl2def__control {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3f4254;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #e4e6ef !important;
    border-radius: 0.42rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.sl2def__control--is-focused {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3f4254;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #e4e6ef !important;
    border-radius: 0.42rem;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.sl2def__value-container {
    padding-left: 1rem !important;
}
.statusSelect .sl2def__value-container {
    padding-left: 2px !important;
}
.sl2def__single-value {
    //height: 100%;
}

.sl2def__single-value .status-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.taskUpdateItem p:last-child {
    margin-bottom: 0;
}

.taskUpdateItem img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}
.react-tenor--result {
    flex-basis: 33.333% !important;
    height: 110px !important;
}

.tenorItem {
    position: relative;
}
.tenorItem img {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bulkActionButtons {
    position: fixed;
    bottom: -500px;
    transform: translate(-50%, 0);
    padding: 20px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.bulkActionButtons.active {
    bottom: 0;
}
.aside-secondary-enabled.aside-enabled .bulkActionButtons {
    left: calc(50% + 212px);
}
.aside-secondary-disabled.aside-enabled .bulkActionButtons {
    left: calc(50% + 50px);
}

.blink_me {
    animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0.4;
    }
}

#kt_quick_panel {
    width: 550px;
    left: -550px;
}
#kt_quick_panel.offcanvas.offcanvas-on {
    -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    left: 0;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Datei wählen"!important;
}