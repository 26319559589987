//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Functions
@import "base/functions";

// Mixins
@import "base/mixins";
@import "components/mixins";
@import "vendors/plugins/mixins";

// Bootstrap mixins
@import "~bootstrap/scss/_mixins.scss";

// Global variables

$primary: #556982;
$primary-hover: #243f5d;
$primary-light: #e1f0ff;
$primary-inverse: #ffffff;

$info: #6993ff;
$info-light: #e1e9ff;
$info-hover: #4a7dff;
$info-inverse: #ffffff;

$success: #1bc5bd;
$success-hover: #0bb7af;
$success-light: #c9f7f5;
$success-inverse: #ffffff;


@import "components/variables.bootstrap";
@import "components/variables.custom";

// 3rd-Party plugins variables
@import "vendors/plugins/variables";

// Theme layout variables
@import "layout/variables";
@import "~react-toastify/scss/main.scss";

@import "~react-tenor/dist/styles.css";

//@import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";

@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-calendars/styles/material.css';
@import '~@syncfusion/ej2-dropdowns/styles/material.css';
@import '~@syncfusion/ej2-gantt/styles/material.css';
@import '~@syncfusion/ej2-grids/styles/material.css';
@import '~@syncfusion/ej2-inputs/styles/material.css';
@import '~@syncfusion/ej2-layouts/styles/material.css';
@import '~@syncfusion/ej2-lists/styles/material.css';
@import '~@syncfusion/ej2-navigations/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-richtexteditor/styles/material.css';
@import '~@syncfusion/ej2-treegrid/styles/material.css';

@import "custom";
